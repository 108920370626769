import { useState, useEffect, useRef } from "react";
import { useLocation, useParams } from "react-router-dom";
import axios from "axios";
// ICONS
import { BsDot } from "react-icons/bs";
import { AiOutlinePlus, AiOutlineMinus } from "react-icons/ai";
// FRAMER MOTION
import { motion, AnimatePresence } from "framer-motion";
import "react-toastify/dist/ReactToastify.css";
//REDUX
import { useDispatch } from "react-redux";
import { counter } from "../redux/basketCounter";

function Product() {
  const ref = useRef(null);
  const [width, setWidth] = useState(0);
  const [count, setCount] = useState(1);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const { productId } = useParams();
  const dispatch = useDispatch();

  const location = useLocation();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data: response } = await axios.get(
          `https://api.cititour.uz/products/${productId}`
        );
        setData(response.data);
      } catch (error) {
        console.error(error.message);
      }
      setLoading(true);
    };

    fetchData();
  }, [productId]);

  useEffect(() => {
    setWidth(ref.current.offsetWidth - 40);
  }, [width]);

  const addToCart = async () => {
    try {
      const { data: response } = await axios.get(
        "https://api.cititour.uz/add-to-cart",
        {
          params: {
            user_id: +localStorage.getItem("userId"),
            product_id: +productId,
            qty: count,
          },
        }
      );
    } catch (error) {
      console.error(error.message);
    }
  };

  const buttonAnimation = () => {
    document.getElementById("btnAnimation").classList.add("shake");
    dispatch(counter(true));

    setTimeout(() => {
      document.getElementById("btnAnimation").classList.remove("shake");
      dispatch(counter(false));
    }, 1000);
  };

  const addToCartCb = () => {
    addToCart();
    buttonAnimation();
  };

  useEffect(() => {
    window.Telegram.WebApp.expand();
  }, [location]);

  const style = {
    height: `calc(${width}px / 1.5)`,
  };

  return (
    <>
      <div className="p-4 max-w-2xl m-auto relative" ref={ref}>
        {loading ? (
          <AnimatePresence>
            <div className="font-medium" key={data.id}>
              <div>
                <img
                  src={data.media}
                  className="m-auto w-full rounded-3xl object-cover bg-[#f2f8ff]"
                  alt=""
                  style={style}
                />
              </div>
              <div
                id="btnAnimation"
                className={`absolute top-[100px] w-12 h-12 left-0 opacity-0 right-0 m-auto transition-all ease-in-out duration-1000 delay-300`}
              >
                <img
                  src={data.media}
                  className="rounded-full w-full h-full object-cover bg-white"
                  alt=""
                />
              </div>
              <div className="flex justify-between items-center">
                <div className="max-w-[50%]">
                  <motion.h3
                    className="text-2xl mt-4 sm:mt-7 sm:text-4xl product-title text-[#fac564]"
                    initial={{
                      y: "20px",
                      opacity: 0,
                    }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{
                      duration: 0.2,
                      delay: 0.2,
                    }}
                  >
                    {data.title}
                  </motion.h3>
                  <motion.div
                    className="text-[#898686] text-xs flex mt-2 sm:text-lg product-price"
                    initial={{
                      y: "20px",
                      opacity: 0,
                    }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{
                      duration: 0.2,
                      delay: 0.3,
                    }}
                  >
                    <span className="font-bold">
                      {data.properties.map((propertie) => (
                        <span className="mr-2" key={propertie.id}>
                          {propertie.value}
                        </span>
                      ))}
                    </span>
                    {data.properties.length > 0 ? (
                      <span className="px-0.5 flex items-center text-[#D7D7D7] text-base sm:text-xl">
                        <BsDot />
                      </span>
                    ) : (
                      ""
                    )}
                    <span>{data.price} UZS</span>
                  </motion.div>
                </div>
                <div className="flex max-w-[50%]">
                  <h3 className="text-xl w-10 sm:w-16 flex items-center sm:text-4xl count-400 dark:text-white">
                    x{count}
                  </h3>
                  <div className="border border-[#fac564] rounded-full overflow-hidden">
                    <button
                      onClick={() =>
                        count === 0 ? setCount(0) : setCount(count - 1)
                      }
                      className="h-full py-3 sm:py-4 pl-[18px] sm:pl-[32px] group hover:bg-[#fac564] pr-[13px] btn-count-left sm:pr-[20px] border-r border-[#fac564]"
                    >
                      <AiOutlineMinus className="text-[20px] sm:text-[35px] text-[#fac564] group-hover:text-black" />
                    </button>
                    <button
                      onClick={() => setCount((prev) => prev + 1)}
                      className="h-full py-3 sm:py-4 pr-[18px] sm:pr-[32px] group hover:bg-[#fac564] pl-[13px] sm:pl-[20px] btn-count-right"
                    >
                      <AiOutlinePlus className="text-[20px] sm:!text-[35px] text-[#fac564] group-hover:text-black" />
                    </button>
                  </div>
                </div>
              </div>
              <motion.p
                className="text-base mt-3 text-[#979797] sm:text-lg product-text"
                initial={{
                  y: "20px",
                  opacity: 0,
                }}
                animate={{ y: 0, opacity: 1 }}
                transition={{
                  duration: 0.2,
                  delay: 0.4,
                }}
              >
                {data.description}
              </motion.p>
              {/* <div>{state && JSON.stringify(body, null, 4)}</div> 
             <div>{state && JSON.stringify(state, null, 4)}</div>  */}
            </div>
          </AnimatePresence>
        ) : (
          <div className="animate-pulse">
            <div
              className="bg-[#d9d9d9] w-full rounded-3xl"
              style={style}
            ></div>
            <div className="h-[24px] sm:h-7 bg-[#d9d9d9] rounded mt-6 sm:mt-7 w-4/5"></div>
            <div className="h-3.5 sm:h-5 bg-[#d9d9d9] rounded mt-2.5 w-1/2"></div>
            <div className="h-3 sm:h-5 bg-[#d9d9d9] rounded mt-[16px]"></div>
            <div className="h-3 sm:h-5 bg-[#d9d9d9] rounded mt-1"></div>
          </div>
        )}
      </div>
      {loading ? (
        +data === 0 ? (
          ""
        ) : (
          <button
            className="fixed w-full bottom-0 bg-[#fac564] text-black h-[56px] text-sm font-medium"
            onClick={() => addToCartCb()}
          >
            Добавить в корзину
          </button>
        )
      ) : (
        ""
      )}
    </>
  );
}

export default Product;
