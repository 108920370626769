import { useState, useEffect, useRef } from "react";
import axios from "axios";
import Empty from "../components/Empty";
import { useLocation } from "react-router-dom";
//ICON
import { BsDot } from "react-icons/bs";
// FRAMER MOTION
import { motion, AnimatePresence } from "framer-motion";
//REACT - TOASTIFY
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
//REDUX
import { useDispatch } from "react-redux";
import { counter } from "../redux/basketCounter";

import Modal from "react-modal";
import MapPickerLocation from "../components/MapPicker";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
  },
};

export default function Basket() {
  const ref = useRef(null);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const dispatch = useDispatch();

  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const [comment, setComment] = useState("");
  const [payment, setPayment] = useState(1);
  const [total, setTotal] = useState(0);
  const [visible, setVisible] = useState(false);
  const [zoom, setZoom] = useState(15);
  const [locations, setLocation] = useState({
    lat: 41.329338257493795,
    lng: 69.24170232350207,
  });

  const location = useLocation();

  function handleChangeLocation(lat, lng) {
    setLocation({
      lng,
      lat,
    });
  }

  const onValueChange = (event) => {
    setPayment(+event.target.value);
  };

  useEffect(() => {
    if (data.length > 0) {
      let price = 0;
      data.forEach((e) => {
        price = price + +e.amount;
      });
      setTotal(price);
    }
  }, [data]);

  // ** Update Window Width
  const handleWindowHeight = () => {
    setWindowHeight(window.innerHeight);
  };

  //** Sets Window Size & Layout Props
  useEffect(() => {
    if (window !== undefined) {
      window.addEventListener("resize", handleWindowHeight);
    }
  }, [windowHeight]);

  const notify = () =>
    toast.success("Очистить корзину", {
      position: "bottom-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const { data: response } = await axios.get(
        `https://api.cititour.uz/cart`,
        {
          params: {
            user_id: localStorage.getItem("userId"),
          },
        }
      );

      buttonAnimation();
      setData(response.data);
    } catch (error) {
      console.error(error.message);
    }
    setLoading(true);
  };

  const buttonAnimation = () => {
    dispatch(counter(true));

    setTimeout(() => {
      dispatch(counter(false));
    }, 500);
  };

  const clearCart = async () => {
    try {
      const { data: response } = await axios.get(
        `https://api.cititour.uz/cart-clear`,
        {
          params: {
            user_id: localStorage.getItem("userId"),
          },
        }
      );
      console.log(response);
      fetchData();
      notify();
    } catch (error) {
      console.error(error.message);
    }
  };

  const confrimCheckout = async () => {
    let newAddress = comment;
    try {
      const { data: response } = await axios.get(
        `https://api.cititour.uz/cart/checkout`,
        {
          params: {
            user_id: localStorage.getItem("userId"),
            payment_type: payment,
            "location[latitude]": 41.31117,
            "location[longitude]": 41.31117,
            comment: comment,
            address: newAddress,
          },
        }
      );
      console.log(response);
      fetchData();
      window.Telegram.WebApp.close();
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    window.Telegram.WebApp.expand();
  }, [location]);

  return (
    <>
      <Modal
        isOpen={visible}
        onRequestClose={() => setVisible(false)}
        style={customStyles}
        contentLabel="Example Modal"
      >
        {/* <PlacesWithStandaloneSearchBox
          setLocation={setLocation}
          setZoom={setZoom}
        /> */}
        <MapPickerLocation
          handleChangeLocation={handleChangeLocation}
          height={400}
          zoom={zoom}
          location={locations}
        />
        <div className="w-full flex justify-center mt-4">
          <button
            onClick={() => setVisible(false)}
            className="w-full px-2 py-2 rounded-xl bg-[#31df77] text-[#fff]"
          >
            Подтвердить
          </button>
        </div>
      </Modal>
      <ToastContainer />
      <div
        ref={ref}
        className={`p-4 max-w-2xl m-auto h-[${
          (windowHeight / 100) * 55
        }px] overflow-scroll`}
      >
        <div className="text-base sm:text-xl flex justify-between">
          <span className="font-semibold dark:text-white">Корзина</span>
          <span
            className="font-medium text-[#fac564] cursor-pointer"
            onClick={() => clearCart()}
          >
            Очистить корзину
          </span>
        </div>
        <div className="mt-7 flex flex-col gap-3 sm:gap-6 overflow-auto pr-3 pb-4 border-b border-[#ededed] dark:border-[#555555]">
          {loading ? (
            data?.length === 0 ? (
              <Empty />
            ) : (
              data.map((item, i) => (
                <AnimatePresence key={item.id}>
                  <motion.div
                    key={item.id}
                    className="flex justify-between font-medium items-center"
                    initial={{
                      y: "20px",
                      opacity: 0,
                    }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{
                      duration: 0.2,
                      delay: 0.2 + i / 5,
                    }}
                  >
                    <div className="flex">
                      <img
                        src={item.product.media}
                        className="w-[48px] sm:w-[80px] h-[48px] object-cover sm:h-[80px] rounded-lg bg-[#f2f8ff]"
                        alt={item.product.name}
                      />
                      <div className="flex flex-col ml-2 sm:ml-4 justify-center">
                        <span className="text-sm sm:text-xl dark:text-white">
                          {item.product.name}
                        </span>
                        <span className="text-[#898686] text-xs mt-0.5 sm:text-base font-medium">
                          {item.product.properties.map((propertie) => (
                            <span key={propertie.id}>{propertie.value}</span>
                          ))}
                        </span>
                      </div>
                    </div>
                    <div className="text-[#898686] text-xs sm:text-base flex mt-1">
                      <span className="text-[#ABABAB]">x{item.qty}</span>
                      <span className="px-0.5 flex items-center text-[#D7D7D7] text-base">
                        <BsDot />
                      </span>
                      <span>{item.product.price} UZS</span>
                    </div>
                  </motion.div>
                </AnimatePresence>
              ))
            )
          ) : (
            Array(5)
              .fill(0)
              .map((e, index) => (
                <div
                  className="animate-pulse flex w-full gap-2 sm:gap-4 items-center"
                  key={index}
                >
                  <div className="bg-[#d9d9d9] w-[48px] sm:w-[80px] h-[48px] sm:h-[80px] rounded-lg"></div>
                  <div className="flex-1 space-y-6 py-1 flex flex-col justify-center">
                    <div className="space-y-3">
                      <div className="h-3 sm:h-5 bg-[#d9d9d9] rounded w-4/5"></div>
                      <div className="h-2.5 sm:h-4 bg-[#d9d9d9] rounded w-1/3"></div>
                    </div>
                  </div>
                  <div className="h-3 sm:h-5 bg-[#d9d9d9] rounded w-1/3"></div>
                </div>
              ))
          )}
        </div>
        {loading ? (
          data?.length === 0 ? (
            <div className="mt-3 sm:mt-6 text-xl sm:text-2xl text-center dark:text-white">
              <p>Ваша корзина пуста</p>
            </div>
          ) : (
            <>
              <div className="mt-[5px] sm:mt-8 pb-4 border-b border-[#ededed] dark:border-[#555555]">
                <div className="flex justify-between font-medium items-center py-4 px-2 mb-2">
                  <div className="flex font-medium items-center">
                    <div className="px-2 py-1 rounded-xl bg-[#efefef] ">📍</div>
                    <div className="ml-4">
                      <strong className="dark:text-white">Адрес</strong>
                    </div>
                  </div>
                  <button
                    className="text-[#fac564]"
                    type="primary"
                    onClick={() => setVisible(true)}
                  >
                    Карта
                  </button>
                </div>
                <textarea
                  onChange={(e) => setComment(e.target.value)}
                  type="text"
                  className="w-full font-thin placeholder:text-[#a8a5a5] sm:text-sm dark:bg-[#121618] text-[#72777A] border border-[#efefef] dark:border-[#555555] bg-[#efefef] py-[11px] sm:py-4 px-4 sm:px-7 rounded-2xl"
                  placeholder="Комментарии к заказу (Дом этаж, код от домофона и т.д)"
                />
                {/* <p className="mt-3 sm:mt-6 text-[#979797] text-sm sm:text-xl">
                Ждем ваши пожелания, жалобы или то что вы хотели бы видеть в
                нашей работе или в списке нашей продукции...
              </p> */}
              </div>
              <div className="mb-4 dark:text-white">
                <div className="text-base sm:text-xl flex justify-between py-4">
                  <span className="font-thin">Выберите тип оплаты</span>
                </div>

                <div className="flex font-medium items-center mb-2">
                  <div className="px-2 py-1 rounded-xl bg-[#efefef]">💸</div>
                  <div className="w-full flex justify-between items-center ml-4">
                    <label htmlFor="cash">C наличными</label>
                    <input
                      onChange={onValueChange}
                      checked={payment == 1}
                      type="radio"
                      id="cash"
                      name="cash"
                      value={1}
                    />
                  </div>
                </div>
                <div className="flex font-medium items-center">
                  <div className="px-2 py-1 rounded-xl bg-[#efefef]">💳</div>
                  <div className="w-full flex justify-between items-center ml-4">
                    <label
                      htmlFor="payme"
                      className="font-thin dark:text-white"
                    >
                      Payme
                    </label>
                    <input
                      onChange={onValueChange}
                      checked={payment == 2}
                      type="radio"
                      id="payme"
                      name="payme"
                      value={2}
                    />
                  </div>
                </div>
              </div>

              <div className="fixed-footer border-t border-[#ededed] dark:border-[#555555] dark:bg-[#121618] py-2">
                <div className="w-full flex justify-between items-center mb-2">
                  <div className="font-thin text-[#72777A]">Сумма товара</div>
                  <div className="font-medium dark:text-white">
                    {total.toLocaleString("en-ZA")} сум
                  </div>
                </div>
                <div className="w-full flex justify-between items-center mb-3">
                  <div className="font-thin text-[#72777A]">Сумма Доставки</div>
                  <div className="font-medium dark:text-white">Бесплатно</div>
                </div>
                <div className="w-full flex justify-between items-center">
                  <div className="font-bold text-[#72777A] text-xl">Итого</div>
                  <div className="font-bold  text-xl dark:text-white">
                    {total.toLocaleString("en-ZA")} сум
                  </div>
                </div>
              </div>
            </>
          )
        ) : null}
      </div>
      {loading ? (
        data == 0 ? (
          ""
        ) : (
          <button
            className="fixed w-full bottom-0 bg-[#fac564] text-black h-[56px] text-sm font-medium"
            onClick={() => confrimCheckout()}
          >
            Оформить
          </button>
        )
      ) : (
        ""
      )}
    </>
  );
}
