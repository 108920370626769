import axios from "axios";

// Add a request interceptor
axios.interceptors.request.use(function (config) {
  // Do something before request is sent
  config.baseURL = "https://api.cititour.uz/";
  // config.headers.common["Access-Control-Allow-Origin"] = "*";
  // config.headers.common["X-Language-Code"] = localStorage.getItem("lang");
  // config.headers.common["X-Language-Code"] = localStorage.getItem("lang");
  // config.withCredentials = true;
  // mode: 'cors',
  // headers: {
  //   'Access-Control-Allow-Origin':'*'
  // }
  return config;
});

export default {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
  patch: axios.patch,
};
