import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import axios from "axios";
//ICONS
import { FiArrowLeft } from "react-icons/fi";
import { BsHandbag } from "react-icons/bs";
//REDUX
import { useSelector } from "react-redux";

function Header() {
  const history = useNavigate();
  const selector = useSelector((state) => state.counter);
  const [count, setCount] = useState(0);

  const alviero = `/img${
    localStorage.getItem("theme") === "dark" ? "/Gold.svg" : "/Chokolate.svg"
  }`;

  
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const { data: response } = await axios.get(
        `https://api.cititour.uz/cart`,
        {
          params: {
            user_id: localStorage.getItem("userId"),
          },
        }
      );
      setCount(response.data.length);
    } catch (error) {
      console.error(error.message);
    }
  };

  const goBack = () => {
    history(-1);
    window.Telegram.WebApp.MainButton.hide();
  };

  useEffect(() => {
    fetchData();
    if (selector) {
    }
  }, [selector]);

  return (
    <div className="px-2 sticky top-0 bg-white dark:bg-[#121618] z-50">
      <div className="px-4 py-4 border-b border-[#ededed] dark:border-[#555555]">
        <div className="flex justify-between items-center">
          <FiArrowLeft
            className="text-2xl text-[#979797] cursor-pointer"
            onClick={goBack}
          />
          <Link to="/">
            <img src={alviero} className="w-[70px]" alt="Logo" />
          </Link>
          <Link
            to="/basket"
            className={`relative ${selector ? "basketAnime" : null}`}
          >
            <BsHandbag className="text-2xl text-[#979797]" />
            <span
              className={`absolute top-[-3px] left-4 bg-red-600 text-white rounded-full text-[8px] px-[4px]
              `}
            >
              {count}
            </span>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Header;
